import React, { ReactNode } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import GlobalStyles from './GlobalStyles'
import theme from '../themes/default'

type Props = {
  children: ReactNode
  smoothScroll?: boolean
  withHero?: boolean
}

const LayoutMinimal = ({ children, smoothScroll }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles smoothScroll={smoothScroll} />
        <main>{children}</main>
      </>
    </ThemeProvider>
  )
}

LayoutMinimal.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutMinimal
