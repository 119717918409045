import React from 'react'
import styled from 'styled-components'
import { graphql, Link as GatsbyLink } from 'gatsby'
import VisuallyHidden from '@reach/visually-hidden'
import LayoutMinimal from '../components/LayoutMinimal'
import Seo from '../components/Seo'
import SocialLinksMinimal from '../components/SocialLinksMinimal'
import { getLinkConfig } from '../utils'
import FlairText from '../components/FlairText'
import LogoSeal from '../components/LogoSeal'

type Props = {
  data: {
    sanityLinkList: {
      links: {
        _key: string
        displayText: string
        url: string
      }[]
    }
  }
}

function LinksPage({ data }: Props) {
  const { links } = data.sanityLinkList || { links: [] }
  return (
    <LayoutMinimal>
      <Seo
        title="Links"
        description="Links to Cody Calligraphy's most recent posts and workshops."
      />
      <Content>
        <GatsbyLink to="/">
          <VisuallyHidden>Cody Calligraphy</VisuallyHidden>
          <LogoSealWithLayout />
        </GatsbyLink>
        <List>
          {links.map(link => {
            const { _key, displayText, url } = link
            const linkConfig = getLinkConfig(url)
            return (
              <Item key={_key}>
                <Link
                  as={linkConfig.component}
                  to={linkConfig.isInternal ? linkConfig.url : undefined}
                  href={linkConfig.isExternal ? linkConfig.url : undefined}
                >
                  {displayText}
                </Link>
              </Item>
            )
          })}
        </List>
        <SocialLinksMinimalWithLayout />
      </Content>
    </LayoutMinimal>
  )
}

export default LinksPage

export const query = graphql`
  query LinkTreeQuery {
    sanityLinkList(title: { eq: "Link Tree" }) {
      title
      links: items {
        _key
        displayText
        url
      }
    }
  }
`

const LogoSealWithLayout = styled(LogoSeal)`
  max-width: 100%;
  width: 185px;
`

const Content = styled.div`
  background: ${({ theme }) => theme.colors.highlight};
  color: white;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;
  text-align: center;
`

const List = styled.ul`
  max-width: 400px;
`

const SocialLinksMinimalWithLayout = styled(SocialLinksMinimal)`
  font-size: 1.125rem;
  margin-top: 1.5rem;
`

const Item = styled.li`
  margin: 1rem 0;
  font-size: 1.125rem;
  line-height: 1.5em;
  border: 0.5px solid white;
`

const Link = styled(FlairText)<{ to?: string }>`
  display: block;
  font-size: 0.6875rem;
  line-height: 1.8181818182em;
  padding: 1rem 1rem;
  transition: transform 0.1s;

  &:hover,
  &:focus {
    transform: scale(1.05);
  }
`
